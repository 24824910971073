<template>
  <div class="iss-main contactFrequency">
    <div class="imgClass">
      <img src="@/assets/images/dashboard/AiImg.png" alt="" class="AiImg" />
    </div>
    <div class="titile">联系人实体信息AI智能识别中...</div>
    <div class="shuliang mt-40">
      <div class="shuliangL">{{ now }}/{{ count }}</div>
      <div class="shuliangR">{{ percentage ? percentage : 0 }}%</div>
    </div>
    <!-- 进度条 :percent="50"  -->
    <div class="issProgress">
      <a-progress
        :status="showStatus"
        :percent="percentage"
        stroke-color="#FF7B00"
      />
    </div>
    <div class="tips">
      预计还需要{{ minutes }}分{{
        seconds
      }}秒您可以先去忙晚点再来，识别完了您可通过首页提示点击进入继续整理。
    </div>
    <!--  按钮需要判断   -->

    <div class="comeBack cursor-p" v-show="isShow" @click="gotoToday">
      晚点再来
    </div>
  </div>
</template>

<script>
import { Progress, message } from 'ant-design-vue';
import { onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import dashboardApi from '@/api/dashboard';
import todayApi from '@/api/today';

export default {
  components: {
    AProgress: Progress,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      count: '0',
      now: '0',
      interval: '0',
      // 百分比
      percentage: '',
      isShow: true,
      minutes: '0',
      seconds: '0',
      showStatus: 'active',
    });
    const form = reactive({});

    // 查询识别状态
    const getImportContactInfoIdentify = () => {
      dashboardApi
        .getImportContactInfoIdentify({
          userId: store.state.account.user.id,
          importSituation: route.query.importStatus,
        })
        .then(res => {
          console.log('res 识别状态', res);
          state.count = res.count;
          state.now = res.now;
          // count  总数
          // now 当前数量
          // condition  识别状态（start 开始、afoot 识别中、completed 完成）

          // 每次计算的毫秒数
          const milliseconds = 230;
          // 计算总毫秒数
          const totalMilliseconds = res.count * milliseconds;
          // 计算分钟和秒
          const totalSeconds = Math.floor(totalMilliseconds / 1000);
          const minutes = Math.floor(totalSeconds / 60);
          const seconds = totalSeconds % 60;
          state.minutes = minutes;
          state.seconds = seconds;
          console.log('shijian', `${minutes} 分 ${seconds} 秒`);

          let numerator = parseInt(res.now, 10);
          let denominator = parseInt(res.count, 10);

          // 计算百分比
          let isPercentage = Math.round((numerator / denominator) * 100);

          // 确保不超过100%
          state.percentage = Math.min(100, isPercentage);
          console.log('state.percentage', state.percentage);

          // 100%后关闭进度条动态效果
          if (state.percentage === 100) {
            state.showStatus = 'exception';
          }

          if (res.condition === 'start') {
            message.info('AI智能识别中');
          } else if (res.condition === 'completed') {
            if (state.interval) {
              clearInterval(state.interval);
              console.log('AI定时器已清除');
            }

            setTimeout(() => {
              router.push({
                path: '/dashboard/dataRecognition',
                query: route.query,
              });
            }, 1500);
          }
        });
    };

    // 存储页面行为
    dashboardApi
      .setBehaviorPage({
        userId: store.state.account.user.id,
        importSituation: 0,
        pageName: '/dashboard/AiIdentify',
      })
      .then(() => {});

    todayApi
      .getContactImportSituation('', {
        userId: store.state.account.user.id,
      })
      .then(res => {
        console.log('res56', res);
        // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
        if (res === 'untreated' && res === 'complete') {
          state.isShow = true;
        } else if (res === 'incomplete') {
          state.isShow = false;
        }
      });

    onMounted(() => {
      getImportContactInfoIdentify();
      state.interval = setInterval(() => {
        console.log('AI定时器正在运行...');
        getImportContactInfoIdentify();
      }, 1500);
    });

    // 在组件销毁之前清除定时器
    onBeforeUnmount(() => {
      if (state.interval) {
        clearInterval(state.interval);
        console.log('AI定时器已清除');
      }
    });

    return {
      ...toRefs(state),
      form,

      gotoToday() {
        router.push({
          path: '/today',
          query: route.query,
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main {
  padding: 104px 265px 193px;
  text-align: center;
}
.issProgress {
  margin-top: 24px;
  color: #ff7b00;
}

.imgClass {
  .AiImg {
    width: 240px;
    height: 240px;
  }
}
.titile {
  font-size: 24px;
  margin-top: 24px;
}
.shuliang {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ff7b00;
  font-size: 18px;
  .shuliangL {
  }
  .shuliangR {
  }
}
.tips {
  color: #ff7b00;
  font-size: 14px;
  margin-top: 22px;
  margin-bottom: 40px;
}

.comeBack {
  margin: auto;
  background: linear-gradient(270deg, #fdbc25 0%, #ff8e31 37%, #ff7b00 86%);
  box-shadow: 0px 4px 8px 0px rgba(145, 70, 8, 0.24);
  border-radius: 100px;
  font-size: 16px;
  width: 200px;
  padding: 8px 68px;
  color: rgba(255, 255, 255, 0.898);
}
.contactFrequency {
  :deep(.ant-progress-text) {
    display: none;
  }
}
</style>
